<template>
  <FormCreateComponent
    v-if="accessPermissions('ticket.show')"
    :id="id"
  />
</template>

<script>
import FormCreateComponent from './components/FormCreateComponent.vue'
import permissions from '@/libs/permissions'

export default {
  name: 'TicketEditPage',
  components: {
    FormCreateComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
  },
}
</script>
